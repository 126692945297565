@charset "UTF-8";

/*
#styleguide
.m-btn01

ボタン
```

<div class="mB20"><a href="#" class="m-btn01 m-btn01-small">標準ボタン</a></div>
<div class="mB20"><a href="#" class="m-btn01 m-btn01-large">標準ボタン（大）</a></div>
<div class="mB20"><a href="#" class="m-btn01 m-btn01-white">標準ボタン（白）</a></div>
<div class="mB20"><a href="#" class="m-btn01 m-btn01-blue">標準ボタン（青）</a></div>
<div class="mB20"><a href="#" class="m-btn01 m-btn01-black">標準ボタン（黒）</a></div>
<div class="mB20"><a href="#" class="m-btn01 m-btn01-green">標準ボタン（緑）</a></div>
<div class="mB20"><a href="#" class="m-btn01 m-btn01--blank">標準ボタン（別タブ）</a></div>
```
*/

.m-btn01 {
  position: relative;
  display: inline-block;
  @include f-size(14);
  font-weight: 400;
  color: $color2;
  background-color: $color1;
  border-radius: 31.5px;
  height: 63px;
  padding: 18px 35px;
  text-align: left;
  border: 1px solid $color1;
  transition: right .3s ease-in-out, background-color .3s, color .3s;
  @include bpSp {
    height: 50px;
    padding: 12px 26px;
    @include f-size(13);
  }
  &:hover {
    text-decoration: none;
    color: $color1;
    background-color: $color2;
    &:after {
      border-color: $color1;
    }
  }
  &:after {
    content: '\f061';
    display: flex;
    justify-content: center;
    align-items: center;
    top: 50%;
    transform: translateY(-50%);
    @include iconFont();
    position: absolute;
    right: 18px;
    width: 26px;
    border: 1px solid $color2;
    height: 26px;
    border-radius: 50%;
    @include f-size(10);
    transition: all .01s ease-in-out;
    background-color: #fff;
    color: $color2;
  }
  &-large {
    width: 287px;
    @include bpSp {
      width: 240px;
    }
  }
  &-small {
    width: 221px;
    @include bpSp {
      width: 200px;
    }
  }
  &-blue {
    background-color: $color2;
    color: $color1;
    border-color: $color2;
    &:hover {
      background-color:$color1;
      color: $color2;
      &:after {
        border-color: $color2;
      }
    }
    &:after {
      border-color: $color1;
    }
  }
  &-black {
    background-color: $textColor;
    color: $color1;
    border-color: $textColor;
    &:hover {
      background-color:$color1;
      color: $textColor;
      &:after {
        border-color: $textColor;
      }
    }
    &:after {
      border-color: $color1;
      background-color: $color1;
      color: $textColor;
    }
  }
  &-white {
    background-color: #fff;
    color: $color2;
    border-color: #fff;
    &:hover {
      background-color:$color2;
      color: #fff;
      border-color: $color2;
      &:after {
        border-color: #fff;
        background-color: transparent;
      }
    }
    &:after {
      border-color: $color2;
      color: $textColor;
    }
  }
  &-green {
    background-color: #42af35;
    color: $color1;
    border-color: #42af35;
    &::after {
      color: #42af35;
      background-color: #fff;
      border-color: #fff;
    }
    &:hover {
      background-color:$color1;
      color: #42af35;
      &:after {
        border-color: #42af35;
      }
    }
  }
  &-viewmore {
    font-family: $Roboto;
  }
  &--blank{
    padding-right: 32px;
    &::after {
      content: '';
      border: 0;
      background-size: cover;
      border-radius: 0;
      background-image: url(../img/common/ico-window.png);
      width: 16px;
      height: 11px;
    }
    
    &:hover {
      &::after {
        background-image: url(../img/common/ico-window_hover.png);
      }
    }
  }
}

/*
#styleguide
.m-btn02

別タブで開くボタン
```

<a href="#" class="m-btn02">身体に不調のある方へ</a>
```
*/
.m-btn02 {
  display: inline-block;
  position: relative;
  @include f-size(14);
  width: 272px;
  background-color: $color1;
  border-radius: 27px;
  padding: 13.5px 37px;
  height: 53px;
  border: 1px solid $color1;
  transition: all .3s ease-in-out;
  &:hover {
    text-decoration: none;
    background-color: transparent;
    color: $color1;
  }
  &:after {
    content: '';
    position: absolute;
    background-image: url(../img/ico-window.png);
    width: 16px;
    height: 11px;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
  }

  &-w {
    &:hover {
      &::after {
        background-image: url(../img/ico-window-white.png);
        background-size: contain;
      }
    }
  }
}

/*
#styleguide
.m-btn03

テキスト＋右にアイコンが有るボタン
```

<a href="#" class="m-btn03">会社概要</a>
<a href="#" class="m-btn03--blank">会社概要</a>
```
*/
.m-btn03 {
  position: relative;
  @include f-size(15);
  color: $color2;
  padding-right: 47px;
  @include bpSp {
    @include f-size(13);
    padding-right: 35px;
  }
  &:hover {
    text-decoration: none;
    &::after {
      right: -7px;
    }
  }
  &::after {
    content: '\f061';
    display: flex;
    justify-content: center;
    align-items: center;
    top: 50%;
    transform: translateY(-50%);
    @include iconFont();
    position: absolute;
    right: 0px;
    width: 26px;
    border: 1px solid $color2;
    height: 26px;
    border-radius: 50%;
    background-color: #fff;
    @include f-size(10);
    transition: right .1s ease-in-out, background-image .1s ease-in-out;
    @include bpSp {
      @include f-size(9);
      width: 24px;
      height: 24px;
      background-color: $color1;
    }
  }
  &--blank{
    padding-right: 32px;
    &::after {
      content: '';
      border: 0;
      background-size: cover;
      border-radius: 0;
      background-image: url(../img/common/ico-window.png);
      width: 16px;
      height: 11px;
    }
  }
}

/*
#styleguide
.m-btn04

会社概要　事業所の詳細ボタン
```

<a href="#" class="m-btn04">詳しくはこちら</a>
```
*/
.m-btn04 {
  display: inline-block;
  position: relative;
  width: 170px;
  border-radius: 7px;
  background: $color2;
  color: $color1;
  padding: 6px 18px;
  border: 1px solid $color2;
  transition: all .3s ease-in-out;
  @include f-size(15);
  &:hover {
    text-decoration: none;
    background: $color1;
    color: $color2;
  }
  &:after {
    content: '\f061';
    display: flex;
    justify-content: center;
    align-items: center;
    top: 50%;
    transform: translateY(-50%);
    @include iconFont();
    position: absolute;
    right: 13px;
    width: 20px;
    border: 1px solid $color2;
    height: 20px;
    border-radius: 50%;
    @include f-size(10);
    transition: all .01s ease-in-out;
    background-color: $color1;
    color: $color2;
  }
}

/*
#styleguide
.m-btn05

bonboneの部位に使っているボタン
```

<a href="#" class="m-btn05">肩・胸・鎖骨</a>
```
*/
.m-btn05 {
  display: inline-block;
  @include f-size(15);
  color: $color1;
  position: relative;
  border-radius: 7px;
  border: 2px solid $color2;
  background-color: #009de8;
  padding: 5.5px 20px;
  text-align: left;
  transition: all .3s ease-in-out;
  &:hover {
    text-decoration: none;
    background-color: $color1;
    color: $color2;
    &::after {
      color: $color1;
      background-color: $color2;
    }
  }
  &:after {
    content: '\f061';
    display: flex;
    justify-content: center;
    align-items: center;
    top: 50%;
    transform: translateY(-50%);
    @include iconFont();
    position: absolute;
    right: 13px;
    width: 22px;
    border: 1px solid $color2;
    height: 22px;
    border-radius: 50%;
    @include f-size(8);
    transition: all .01s ease-in-out;
    background-color: $color1;
    color: #009de8;
  }
}


/*
#styleguide
.m-btn06

bonboneの部位に使っているボタン
```

<a href="#" class="m-btn06">テーピング</a>
```
*/
.m-btn06 {
  position: relative;
  display: inline-block;
  @include f-size(15);
  border-radius: 7.5px;
  border: 2px solid $color2;
  width: 236px;
  padding: 9.5px 24px;
  color: $color2;
  transition: right .3s ease-in-out, background-color .3s, color .3s;
  &:hover {
    text-decoration: none;
    color: $color1;
    background-color: $color2;
  }
  &::after {
    content: '\f061';
    display: flex;
    justify-content: center;
    align-items: center;
    top: 50%;
    transform: translateY(-50%);
    @include iconFont();
    position: absolute;
    right: 13px;
    width: 22px;
    border: 1px solid $color2;
    height: 22px;
    border-radius: 50%;
    @include f-size(8);
    transition: all .01s ease-in-out;
    background-color: $color1;
    color: $color2;

  }
}



/*
#styleguide
.m-linkblock

リンクブロック
```

<a href="#" class="m-linkblock">
  <span class="img-overhide">
    <img src="<%= path %>/assets/img/top/company-img04.jpg" alt="SDGsの取り組み" class="fadein-img">
  </span>
  <p class="txt">SDGsの取り組み</p>
  <div class="btn-wrap">
    <span class="m-btn03">VIEW MORE</span>
  </div>
</a>
```
*/
.m-linkblock {
  display: block;
  transition: all .2s;
  &:hover {
    text-decoration: none;
    img {
      opacity: 0.8;
      @include bpSp {
        opacity: 1;
      }
    }
  }
}