@charset "UTF-8";

/*
#styleguide
.m-text01

文字サイズ16、行間30指定の文章。あまり使ってない。
```
<p class="m-text01">肉体的な負担が多く、身体の悩みを抱える方の多い製造業・建設業・運送業をはじめとする産業や農業、また医療・福祉業界で働く方へ向けて。作業時の負担を軽減するサポートギアで、働く方を元気にしたい。</p>
```
*/
.m-text01 {
  @include f-size(16);
  @include line-h(32,60);
  font-weight: 400;
  @include bpSp {
    @include f-size(14);
  }
}

/*
#styleguide
.m-title-img

英語（画像）+日本語の見出し
```
<h2 class="m-title-img">
  <img src="./assets/img/top/title-img-news.png" alt="News">
  <p class="m-title-img__ja">ニュース</p>
</h2>
```
*/
// Title
.m-title-img {
  &__ja {
    @include f-size(18);
    color: $color2;
    font-weight: 500;
    margin-top: 5px;
    color: $color6;
    @include bpSp {
      @include f-size(13);
    }
  }
}


/*
#styleguide
.m-title01

下に波模様がある見出し
```
<h2 class="m-title01">標準見出し</h2>
<h2 class="m-title01 m-title01-black">標準見出し（黒）</h2>
<h2 class="m-title01 m-title01-white">標準見出し（白）</h2>
```
*/
.m-title01 {
  position: relative;
  text-align: center;
  @include f-size(30);
  font-weight: 500;
  color: $color6;
  padding-bottom: 30px;
  @include bpSp {
    @include f-size(22);
    padding-bottom: 10px;
  }
  &::after {
    content:'';
    position: absolute;
    background-image: url(../img/common/border-title01.png);
    width: 46px;
    height: 7px;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    @include bpSp {
      width: 36px;
      height: 6px;
      background-size: cover;
    }
  }
  &-black {
    color: $textColor;
    &::after {
      background-image: url(../img/common/border-title01-black.png);
    }
  }
  &-white {
    color: $color1;
    &::after {
      background-image: url(../img/common/border-title01-white.png);
    }
  }
}
