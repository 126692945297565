@charset "UTF-8";

@use "../_setting" as *;
@use "../_mixin" as *;

/* レイアウト
*************************************/
.contact {
  &-form {
    // テーブル
    &__table {
      width: 100%;
      margin-top: 60px;
      table-layout: fixed;
      border-top: 1px solid #e3e3e3;
      @include bpSp {
        margin-top: 30px;
      }

      th,
      td {
        font-weight: 400;
        line-height: 1.4;
        vertical-align: top;
        border-bottom: 1px solid #e3e3e3;
        @include bpSp {
          display: block;
        }
      }

      th {
        width: 250px;
        @include f-size(16);
        font-weight: 500;
        padding: 28px 20px 20px 0;
        @include bpSp {
          width: 100%;
          padding: 20px 0 0;
          border-bottom: none;
        }
      }
      td {
        padding: 20px 0 20px 20px;
        @include bpSp {
          padding: 10px 0 20px;
        }
      }
    }

    // ボタン
    &__buttons {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 25px;
      button:not(:first-of-type) {
        margin-left: 30px;
      }
      .m-btn01 {
        width: 220px;
        &:disabled {
          opacity: 0.4;
          pointer-events: none;
          filter: grayscale(100%);
        }
      }
    }
  }

  // プラボリ
  &-privacy {
    padding-top: 30px;
    .m-checkbox-list {
      justify-content: center;
      align-items: center;
    }
    &__check {
      @include f-size(16);
      font-weight: 500;
      @include bpSp {
        @include f-size(14);
      }
      a {
        color: #2e7fb7;
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}

/* MW FORM
*************************************/
// 入力画面
.mw_wp_form_input {
  .is-input-hidden {
    display: none;
  }
}

// 確認画面
.mw_wp_form.mw_wp_form_confirm {
  .is-input-hidden {
    display: block;
  }
  .is-confirm-hidden {
    display: none;
  }
  .m-select-wrapper {
    &::after {
      content: none;
    }
  }
  .contact-form__table {
    th {
      padding-top: 20px;
    }
  }
  .m-checkbox-list {
    padding: 0;
  }
  .m-radio-list {
    padding: 0;
    margin-bottom: 0;
  }
  .m-input-horizontal {
    padding-bottom: 0 !important;
    &-col2 {
      flex-direction: row;
      &>span,
      &>div {
        width: auto;
        &+span,
        &+div {
          padding-left: 1em;
        }
      }
    }
  }
}

// 完了画面
.contact-form-thanks {
  &__title {
    @include f-size(24);
    line-height: 1.4;
    font-weight: 500;
    text-align: center;
    @include bpSp {
      @include f-size(20);
    }
  }
  &__text {
    @include line-h(32,60);
    padding-top: 70px;
    padding-bottom: 45px;
    @include bpSp {
      @include f-size(14);
      padding-top: 30px;
      padding-bottom: 0;
    }
    a {
      color: $color2;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
}

/* 入力項目
*************************************/
/*
#styleguide
.m-required

必須
```
<span class="m-required">必須</span>
```
*/
.m-required {
  display: block;
  width: 40px;
  color: #fff;
  @include f-size(13);
  font-weight: 400;
  text-align: center;
  line-height: 1;
  padding: 3px 0 4px;
  border-radius: 3px;
  background-color: $color2;
  float: right;
  @include bpTb {
    @include f-size(12);
    margin-left: 10px;
  }
  @include bpSp {
    float: none;
    display: inline-block;
    margin-left: 10px;
  }
}


/*
#styleguide
.m-input

基本的な入力フィールド
input、select、textarea
```
<input type="text" class="m-input">
<input type="text" class="m-input m-input--short" value="長い入力フィールド">
<input type="text" class="m-input m-input--half" value="半分の入力フィールド">
<input type="text" class="m-input m-input--long" value="短い入力フィールド">
```
*/
.m-input {
  @include f-size(16);
  font-weight: 500;
  font-family: $Gothic;
  background-color: #fff;
  border: 1px solid #cecece;
  padding: 8px 10px;
  transition: all .2s;
  &::placeholder {
    color: #bcbcbc;
  }
  &:focus {
    outline: none;
    box-shadow: 0 0 4px rgba($textColor, .2);
  }
  &--short {
    width: 150px; //電話番号・郵便番号
  }
  &--half {
    width: 150px; //姓名など2つならびのもの
    max-width: 100%;
  }
  &--long {
    width: 100%; //標準
  }
}
select.m-input {
  border: 1px solid #adadad;
  width: 100%;
  padding-right: 32px;
  @include bpSp {
    @include f-size(14);
  }
}
textarea.m-input {
  resize: vertical;
  height: 10em;
}


/*
#styleguide
.m-select-wrapper

デフォルトだと矢印がつかないselectに矢印をつけるために使う
```
<div class="m-select-wrapper">
  <select name="" class="m-input" id="">
    <option value="">--</option>
    <option value="">1</option>
    <option value="">2</option>
  </select>
</div>
```
*/
.m-select-wrapper {
  display: inline-block;
  position: relative;
  /* &::after {
    content: "";
    display: block;
    pointer-events: none;
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%) rotate(135deg);
    border-top: 1px solid $textColor;
    border-right: 1px solid $textColor;
    width: 5px;
    height: 5px;
  } */
}


/*
#styleguide
.m-radio-list

汎用ラジオボタン
```
<label class="m-radio">
  <input type="radio" name="sex">
  <span>女性</span>
</label>
```
*/
.mwform-radio-field label {
  font-weight: inherit !important;
}
.m-radio-list {
  display: flex;
  flex-wrap: wrap;
  padding-top: 7px;
  padding-bottom: 7px;
  @include bpTb {
    flex-direction: column;
  }
  @include bpSp {
    padding-top: 0;
    padding-bottom: 0;
  }
  label {
    display: inline-block;
  }
  &>span {
    display: flex;
    align-items: center;
    position: relative;
    margin-left: 0 !important;
    padding-right: 50px;
    padding-left: 24px;
    @include bpSp {
      padding-right: 0;
    }
    span {
      display: inline-block;
      line-height: 1.4;
    }
  }
  input[type=radio] {
    opacity: 0;
    appearance: none;
    position: absolute;
    &+span::before,
    &+span::after {
      content: "";
      position: absolute;
      border-radius: 50%;
    }
    &+span::before {
      top: 2px;
      left: 0;
      background-color: #fff;
      border: 1px solid #aeaeae;
      width: 18px;
      height: 18px;
    }
    &+span::after {
      opacity: 0;
      top: 5px;
      left: 3px;
      background-color: $textColor;
      width: 12px;
      height: 12px;
    }
    &:checked+span::before {
      background-color: #fff;
    }
    &:checked+span::after {
      opacity: 1;
    }
  }
}


/*
#styleguide
.m-checkbox-list

汎用チェックボックス
```
<label class="m-input-horizontal__item m-checkbox">
  <input type="checkbox" name="チェックボタン縦組み">
  <span>選択肢</span>
</label>
```
*/
.mwform-checkbox-field label {
  font-weight: inherit !important;
}
.m-checkbox-list {
  display: flex;
  flex-wrap: wrap;
  padding-top: 8px;
  padding-bottom: 11px;
  @include bpTb {
    flex-direction: column;
  }
  @include bpSp {
    padding-bottom: 0;
    padding-top: 0;
    margin-top: -.1rem;
  }
  &>span {
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 24px;
    span {
      display: inline-block;
      vertical-align: middle;
      line-height: 1.4;
    }
  }
  label {
    display: inline-block;
    line-height: 1;
  }
  input[type=checkbox] {
    opacity: 0;
    appearance: none;
    position: absolute;
    &+span::before,
    &+span::after {
      content: "";
      position: absolute;
    }
    &+span::before {
      top: 0.2rem;
      left: 0;
      background-color: #fff;
      border: 1px solid #aeaeae;
      border-radius: 3px;
      width: 18px;
      height: 18px;
      @include bpSp {
        top: .1rem;
      }
    }
    &+span::after {
      opacity: 0;
      top: 1rem;
      left: 6px;
      transform: translateY(-60%) rotate(45deg);
      border-right: 2px solid $textColor;
      border-bottom: 2px solid $textColor;
      width: 6px;
      height: 11px;
    }
    &:checked+span::before {
      background-color: #fff;
    }

    &:checked+span::after {
      opacity: 1;
    }
  }
}


/*
#styleguide
.m-input-vertical

フォームの縦並び
```
<div class="m-input-vertical">
  <span class="mwform-checkbox-field horizontal-item">
    <label>
      <input type="checkbox" name="チェックボタン縦組み" value="選択" class="m-input-vertical__item m-checkbox">
      <span class="mwform-checkbox-field-text">選択</span>
    </label>
  </span>
  <span class="mwform-checkbox-field horizontal-item">
    <label>
      <input type="checkbox" name="チェックボタン縦組み" value="選択肢" class="m-input-vertical__item m-checkbox">
      <span class="mwform-checkbox-field-text">選択肢</span>
    </label>
  </span>
  <span class="mwform-checkbox-field horizontal-item">
    <label>
      <input type="checkbox" name="チェックボタン縦組み" value="選択肢選" class="m-input-vertical__item m-checkbox">
      <span class="mwform-checkbox-field-text">選択肢選</span>
    </label>
  </span>
</div>
```
*/
.mw_wp_form {
  .m-input-vertical {
    display: flex;
    flex-wrap: wrap;
    flex-flow: column;
    // padding-top: 5px;
    // padding-bottom: 10px;
    @include bpSp {
      padding-top: 0;
    }
    .horizontal-item {
      & + .horizontal-item {
        margin-left: 0;
        margin-top: 10px;
      }
      label {
        display: flex;
        align-items: flex-start;
      }
    }
  }
}


/*
#styleguide
.m-input-horizontal

フォームの横並び
```
<div class="m-input-horizontal">
  <label class="m-input-horizontal__item m-checkbox">
    <input type="checkbox" name="チェックボタン縦組み">
    <span>選択</span>
  </label>
  <label class="m-input-horizontal__item m-checkbox">
    <input type="checkbox" name="チェックボタン縦組み">
    <span>選択肢</span>
  </label>
  <label class="m-input-horizontal__item m-checkbox">
    <input type="checkbox" name="チェックボタン縦組み">
    <span>選択肢選</span>
  </label>
</div>
```
*/
.mw_wp_form {
  .m-input-horizontal {
    display: flex;
    flex-wrap: wrap;
    // margin-top: -3px;
    margin-bottom: -10px;
    @include bpSp {
      flex-direction: column;
      padding-top: 0;
      // margin-top: -10px;
    }
    .horizontal-item {
      margin-right: 50px;
      margin-bottom: 10px;
      // margin-top: 10px;
      @include bpTb {
        margin-right: 0;
      }
      @include bpSp {
        margin-right: 0;
        margin-top: 0;
      }
      & + .horizontal-item {
        margin-left: 0;
      }
      label {
        display: flex;
        align-items: flex-start;
      }
    }
    &-col2 {
      &>span,
      &>div {
        width: 50%;
        padding-right: 20px;
        margin-right: 0 !important;
        @include bpSp {
          width: 100%;
          padding-right: 0;
          margin-top: 10px;
        }
      }
    }
    &-col3 {
      &>span,
      &>div {
        width: 33%;
        padding-right: 20px;
        margin-right: 0 !important;
        @include bpTb {
          width: 100%;
          padding-right: 0;
        }
      }
    }
    // 生年月日
    &-birth {
      margin-bottom: 0;
      @include bpSp {
        flex-direction: row;
      }
      .m-select-wrapper {
        margin: 0 .4em 0 .5em;
        @include bpSp {
        }
      }
    }
  }
}