@charset "UTF-8";

/*******************************
Style Start
*******************************/
.is-pc-hidden {
  display: none;
  @include bpTb {
    display: inline-block;
  }
}

.is-sp-hidden {
  display: inline-block;
  @include bpTb {
    display: none;
  }
}

.is-sps-visible {
  display: none;
  @include bpSp {
    display: block;
  }
}

.is-sps-hidden {
  display: block;
  @include bpSp {
    display: none;
  }
}

.img-overhide {
  display: block;
  overflow: hidden;
}

.fadein-img {
  opacity: 0;
  filter: blur(2.4rem) brightness(1.3);
  transform: scale(1.15);
  transition-timing-function: cubic-bezier(0.075, 0.05, 0.000, 1.0);
  transition-duration: .55s;
  transition-property: transform, filter, opacity;
  &.is-active {
    opacity: 1;
    filter: none;
    transform: scale(1);
    animation: fadein-object .55s 1 linear forwards;
  }
}

@keyframes fadein-object {
    0% {
        pointer-events: none;
    }
    99.999% {
        pointer-events: none;
    }
    100% {
        pointer-events: all;
    }
}
