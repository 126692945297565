@charset "UTF-8";

/*
#overview
layout
*/
img {
  -webkit-touch-callout:none;
  -webkit-user-select:none;
  -moz-touch-callout:none;
  -moz-user-select:none;
  touch-callout:none;
  user-select:none;
}

.l-header {
  position: relative;
  padding: 30px 42px 27px 32px;
  display: flex;
  justify-content: space-between;
  z-index: 10;
  @include bpPcs {
    position: fixed;
    padding: 30px;
    align-items: center;
    background-color: $color1;
    top: 0;
    left: 0;
    width: 100%;
  }
  @include bpSp {
    padding: 17px 22px;
  }
  .logo {

    a {
      display: inline-block;
    }
    @include bpSp {
      width: 125px;
    }
  }
  .menu-nav {
    @include bpPcs {
      display: none;
    }
    &__top {
      display: flex;
      justify-content: flex-end;
      .item {
        &:not(:first-of-type) {
          margin-left: 15px;
        }
        .btn-language {
          display: inline-block;
          text-align: center;
          @include f-size(13);
          color: $color2;
          background-color: $color1;
          border-radius: 13.425px;
          border: 1px solid $color2;
          width: 76px;
          &:hover {
            text-decoration: none;
            opacity: 0.8;
          }
        }
        .btn-link {
          display: inline-block;
          width: 235px;
          text-align: center;
          @include f-size(14);
          color: $color1;
          background-color: #016db8;
          border-radius: 13.425px;
          padding: 0 15px;
          &:hover {
            text-decoration: none;
            opacity: 0.8;
          }
          .fas {
            margin-left: 7px;
          }
        }
      }
    }
    &__link {
      display: flex;
      margin-top: 5px;
      .item {
        &:not(:first-of-type) {
          margin-left: 30px;
        }

        .is_active {
          color: $color2;
          .link_icon {
            border: 1px solid $color2!important;
          }
        }
        .link {
          @include f-size(15);
          position: relative;
          transition: all 0.2s;
          &::after {
          }
          &:hover {
            text-decoration: none;
            transition: all 0.2s;

            &::after {
              content: "";
              width: 100%;
              height: 1px;
              background-color: $color2;
              position: absolute;
              bottom: -16px;
              left: 0px;
            }
          }

          &.no-link {
            // pointer-events: none;
            &:hover {
              cursor: pointer;
              color: $color2;
              .link_icon {
                border: 1px solid $color2;
              }
            }

          }

          .link_icon {
            border: 1px solid $textColor;
            border-radius: 20px;
            display: inline-block;
            padding: 0px 4px;
            height: 18px;
            line-height: 0.8;
            margin-left: 5px;
            vertical-align: 1px;

            i {
              @include f-size(10);
            }
          }
        }
      }

      .menu-nav__link__inner {
        position: absolute;
        top: 102px;
        left: 0px;
        width: 100vw;
        background-color: #eaf1f9;
        padding-top: 45px;
        padding-bottom: 45px;

        .menu-child {
          max-width: 1000px;
          margin-left: auto;
          margin-right: auto;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;

          &::after {
            content: "";
            display: inline-block;
            width: 30%;
          }

          &-item {
            width: 49%;

            &-title {
              @include f-size(17);
              margin-bottom: 5px;
            }

          }

          .item-child {
            width: 30%;

            .link-child {
              width: 90%;
              display: block;
              padding-top: 5px;
              padding-bottom: 5px;
              position: relative;
              &:hover {
                opacity: 0.7;
                text-decoration: none;
                &::after {
                  right: 0px;
                }
              }

              &::after {
                content: "\f105";
                @include iconFont;
                position: absolute;
                top: 50%;
                right: 6px;
                transform: translateY(-50%);
                color: $color2;
                transition: right 0.2s;
              }
            }

            &.item-child_style2 {
              width: 31.5%;
              a {
                &:hover {
                  opacity: 0.75;
                  text-decoration: none;
                }
              }
              .link-child {
                width: 100%;
                padding-top: 5px;
                &:hover {
                  // opacity: 1;
                  text-decoration: none;
                }
              }
              &:hover {
                text-decoration: none;
                .link-child {
                  text-decoration: none;
                  &::after {
                    right: 0px;
                  }
                }
              }
            }

            &.item-child_style3 {
              width: 48%;
              a {
                &:hover {
                  opacity: 0.75;
                  text-decoration: none;
                }
              }
              .link-child {
                width: 100%;
                padding-top: 5px;
                &:hover {
                  // opacity: 1;
                  text-decoration: none;
                }
              }
              &:hover {
                text-decoration: none;
                .link-child {
                  text-decoration: none;
                  &::after {
                    right: 0px;
                  }
                }
              }
            }
          }
        }
      }

    }
  }
  .menu-nav-sp {
    display: none;
    overflow-y: auto;
    background-color: $color1;
    z-index: 100;
    position: fixed;
    top: 113px;
    left: 0;
    width: 100%;
    height: 0%;
    transition: all .3s linear;
    @include bpSp {
      top: 63px;
    }
    &.is-active {
      height: calc(100% - 113px);
      @include bpSp {
        height: calc(100% - 63px);
      }
    }
    @include bpPcs {
      display: block;
    }
    .menu {
      .item {
        border-bottom: 1px solid #d2d2d2;
        &.list-trigger {
          .m-parrent {
            position: relative;
            @include f-size(26);
            color: $color6;
            padding: 23px 40px;
            @include bpSp {
              @include f-size(17);
              padding: 15px 20px;
            }
            &.is-open {
              &:after {
                transform: translateY(-20px) rotate(180deg);
                @include bpSp {
                  transform: translateY(-12px) rotate(180deg);
                }
              }
            }
            &:after {
              content: '\f063';
              display: flex;
              justify-content: center;
              align-items: center;
              top: 50%;
              transform: translateY(-50%);
              @include iconFont();
              position: absolute;
              color: $color2;
              right: 43px;
              width: 40px;
              height: 40px;
              border: 1px solid #016db8;
              border-radius: 50%;
              @include f-size(15);
              transition: all .3s linear;
              @include bpSp {
                width: 24px;
                height: 24px;
                @include f-size(11);
                right: 24px;
              }
            }
          }
          .menu-child {
            background-color: #1695e6;
            padding: 30px 50px;
            @include bpSp {
              padding: 10px 24px;
            }
            .ttl {
              @include f-size(20);
              background-color: #45aaeb;
              padding: 9px 17px;
              color: $color1;
              margin: 20px 0 12px;
              @include bpSp {
                @include f-size(14);
                margin: 15px 0 10px;
              }
            }
            .item-child {
              .link-child {
                display: block;
                position: relative;
                @include f-size(22);
                color: $color1;
                padding: 6px 0;
                @include bpSp {
                  @include f-size(15);
                }
                &::after {
                  content: '\f105';
                  position: absolute;
                  @include f-size(22);
                  @include iconFont();
                  right: 10px;
                  top: 50%;
                  transform: translateY(-50%);
                  color: $color1;
                  @include bpSp {
                    @include f-size(15);
                  }
                }
              }
            }
          }
        }
        .link {
          @include f-size(24);
          color: $color6;
          position: relative;
          display: block;
          padding: 23px 40px;
          @include bpSp {
            @include f-size(16);
            padding: 15px 20px;
          }
          &::after {
            content: '\f105';
            position: absolute;
            @include iconFont();
            right: 55px;
            top: 50%;
            transform: translateY(-50%);
            color: $color2;
            @include bpSp {
              right: 34px;
            }
          }
        }
      }
    }
    .links-wrap {
      display: flex;
      justify-content: center;
      margin-top: 45px;
      margin-bottom: 37px;
      @include bpSp {
        margin-top: 25px;
      }
      .item {
        margin: 0 20px;
        @include bpSp {
          margin: 0 10px;
        }
        .link {
          @include f-size(20);
          color: $color6;
          @include bpSp {
            @include f-size(14);
          }
        }
      }
    }
    .language-wrap {
      text-align: center;
      .btn-language {
        display: inline-block;
        text-align: center;
        @include f-size(20.02);
        color: $color2;
        background-color: $color1;
        border-radius: 18px;
        border: 1px solid $color2;
        width: 118px;
        padding: 1px 0;
        @include bpSp {
          @include f-size(15.02);
        }
        &:hover {
          text-decoration: none;
          opacity: 0.8;
        }
      }
    }
    .sns-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 45px;
      @include bpSp {
        margin-top: 25px;
      }
      .item {
        margin: 0 15px;
        @include bpSp {
          margin: 0 7px;
          img {
            width: 70%;
          }
        }
      }
    }
    .links-wrap02 {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      margin-top: 30px;
      margin-bottom: 68px;
      @include bpSp {
        margin-top: 25px;
      }
      .item {
        margin: 10px 0;
        @include bpSp {
          margin: 5px 0;
        }
        .m-btn02 {
          background-color: $color2;
          color: $color1;
          @include f-size(21);
          width: 408px;
          height: 80px;
          border-radius: 40px;
          padding: 20.5px 47px;
          @include bpSp {
            width: 276px;
            @include f-size(14);
            height: 59px;
            border-radius: 40px;
            padding: 14.5px 26px;
          }
          &:after {
            background-image: url(../img/ico-window-white.png);
            width: 25px;
            height: 16px;
            right: 30px;
            background-size: cover;
            background-repeat: no-repeat;
            @include bpSp {
              width: 20px;
              height: 13px;
            }
          }
        }
      }
    }
  }
  .ico-menu {
    position: relative;
    display: none;
    width: 32px;
    height: 28px;
    z-index: 101;
    @include bpPcs {
      display: inline-block;
    }
    &.is-active {
      span {
        &:nth-child(1) {
          transform: translateY(14px) rotate(38deg);
        }
        &:nth-child(2) {
          opacity: 0;
        }
        &:nth-child(3) {
          transform: translateY(-13px) rotate(-38deg);
        }
      }
    }
    span {
      position: absolute;
      display: inline-block;
      width: 32px;
      height: 2px;
      background-color: $color2;
      transition: all .3s ease-in-out;
      &:nth-child(1) {
        top: 0;
      }
      &:nth-child(2) {
        top: 50%;
      }
      &:nth-child(3) {
        top: 98%;
      }
    }
  }
}

.l-main {
  @include bpPcs {
    padding-top: 140px;
  }
  @include bpSp {
    padding-top: 65px;
  }
  &__common {
    padding-top: 16px;
    @include bpPcs {
      padding-top: 120px;
    }
    @include bpSp {
      padding-top: 60px;
    }
    .mainvisual-common {
      position: relative;
      background: rgb(214,240,255);
      background: linear-gradient(90deg, rgba(214,240,255,1) 0%, rgba(238,255,217,1) 100%);
      height: 253px;
      @include bpSp {
        height: 170px;
      }
      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 5.5vw;
        background-image: url(../img/common/border-wave.png);
        background-size: 100%;
        background-repeat: no-repeat;
        left: 0;
        bottom: -32px;
        @include bpSp {
          bottom: -10px;
        }
      }
      &__title {
        position: relative;
        @include f-size(34);
        font-weight: 500;
        color: $color6;
        line-height: 1;
        padding-bottom: 30px;
        padding-top: 97px;
        text-align: center;
        @include bpTb {
          @include f-size(30);
        }
        @include bpSp {
          padding-top: 70px;
          @include f-size(22);
          padding-bottom: 20px;
        }
        &:after {
          content: '';
          position: absolute;
          width: 34px;
          height: 1px;
          background-color: $color6;
          left: 50%;
          transform: translateX(-50%);
          bottom: 0;
        }
      }
      &__bgnone {
        display: flex;
        align-items: center;
        justify-content: center;
        background: none;
        height: 170px;
        @include bpSp {
          height: 120px;
        }
        &::after {
          display: none;
        }
        &__title {
          padding-top: 0;
        }
      }
      &-min {
        height: 215px;
        @include bpSp {
          height: 150px;
        }
        .mainvisual-common__title {
          padding-top: 77px;
          @include bpSp {
            padding-top: 60px;
          }
        }
        &::after {
          bottom: -14px;
          @include breakpoint(1200px) {
            bottom: -11px;
          }
          @include bpSp {
            bottom: -8px;
            background-image: url(../img/common/border-wave_sp.png);
          }
        }
      }
    }
    .breadcrumbs {
      @include f-size(13);
      color: $color6;
      display: flex;
      margin-top: 20px;
      margin-bottom: 123px;
      flex-wrap: wrap;
      @include bpSp {
        margin-bottom: 50px;
        @include f-size(12);
      }
      .link {
        position: relative;
        color: $color6;
        white-space: nowrap;
        &:after {
          content: '＞';
          display: inline-block;
          margin: 0 15px;
        }
      }
    }
    .logo-main-page {
      text-align: center;
    }
    .mainvisual-img {
      display: inline-block;
      width: 100%;
      img {
        width: 100%;
      }
    }
  }
}

.l-footer {
  padding: 43px 0 64px;
  background: rgb(1,109,184);
  background: linear-gradient(90deg, rgba(1,109,184,1) 0%, rgba(5,114,180,1) 50%, rgba(81,207,108,1) 100%);
  position: relative;
  @include bpSp {
    padding: 0 0 30px;
  }
  .pagetotop {
    position: absolute;
    right: 30px;
    top: -94px;
    height: 64px;
    z-index: 2;
    @include bpSp {
      width: 50px;
      height: 50px;
      right: 10px;
    }
  }
  &__wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include bpTb {
      flex-direction: column-reverse;
    }
    &--info {
      padding-left: 60px;
      text-align: center;
      @include bpPcs {
        padding-left: 0;
        margin-top: 50px;
      }
      @include bpSp {
        margin-top: 20px;
      }
      .sns-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        .item {
          .link {
            margin: 0 11.5px;
            @include bpSp {
              margin: 0 8px;
            }
          }
        }
      }
      .btn-wrap {
        padding-left: 10px;
        margin: 35px 0 41px;
        @include bpSp {
          margin: 20px 0;
        }
      }
      .privacy-links {
        display: flex;
        align-items: center;
        margin-top: 27px;
        margin-bottom: 15px;
        .item {
          margin: 0 12.5px;
          .link {
            @include f-size(13);
            color: $color1;
            &:hover {
              opacity: 0.75;
              text-decoration: none;
            }
            @include bpSp {
              @include f-size(12);
            }
          }
        }
      }
      .copyright {
        @include f-size(12);
        color: $color1;
        @include bpSp {
          @include f-size(10);
        }
      }
    }
    &--links {
      width: 50%;
      @include bpTb {
        width: 100%;
      }
      .menu-wrap {
        display: flex;
        justify-content: space-between;
        @include bpTb {
          display: none;
        }
        &__block {
          // &:not(:last-of-type) {
          //   margin-right: 28px;
          // }
          .item {
            line-height: 1.5;
            &.mtop {
              margin-top: 22px;
            }
            .link {
              color: $color1;
              display: inline-block;
              &:hover {
                opacity: 0.75;
                text-decoration: none;
              }
              &-large {
                @include f-size(15);
                margin-bottom: 10px;
              }
              &-small {
                @include f-size(12);
                padding-bottom: 3px;
                padding-top: 3px;
              }
              &-child {
                padding-left: 19px;
                position: relative;
                &:before {
                  content: '';
                  position: absolute;
                  width: 9px;
                  height: 1px;
                  background-color: $color1;
                  left: 0;
                  top: 12px;
                }
              }
              &-none {
                pointer-events: none;
                &:hover {
                  opacity: 1;
                }
              }
            }
          }
        }
      }
      .btn-wrap {
        display: flex;
        flex-wrap: wrap;
        margin-top: 21px;
        margin-bottom: 21px;
        @include bpTb {
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
        .item {
          margin: 5px 0;
          @include bpSp {
            width: 100%;
          }
          &:not(:last-of-type) {
            margin-right: 10px;
            @include bpTb {
              margin-right: 0;
            }
          }
          .m-btn02 {
            @include bpTb {
              background-color: transparent;
              border: 0;
              border-bottom: 1px solid $color1;
              border-radius: 0;
              color: $color1;
              &:after {
                background-image: url(../img/ico-window-white.png);
                width: 18px;
                height: 12px;
                background-size: cover;
              }
            }
            @include bpSp {
              padding: 13.5px 16px;
              width: 100%;
            }
          }
        }
      }
      .bannerlink-wrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        @include bpTb {
          margin: 0 10%;
        }
        @include bpSp {
          margin: 0;
        }
        .item {
          width: 32%;
          margin-bottom: 2%;
          &:last-of-type {
            width: 63%;
            @include f-size(12);
            color: $color1;
            @include bpSp {
              @include f-size(9);
            }
          }
        }
      }
    }
  }
}


.memu-contents-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.4);
  display: none;
  z-index: 5;
  pointer-events: none;
}


body .cc-window {
  right: 10px!important;
  left: auto!important;
  width: 360px!important;
  padding: 30px!important;
  @include f-size(13);
  flex-direction: column!important;
  border-radius: 10px;

  @include bpTb {
    width: 96%!important;
    left: 50%!important;
    max-width: 100%!important;
    transform: translateX(-50%);
    @include f-size(11);
    right: 0px!important;
  }

  .cookie_close.cc-btn {
    position: absolute;
    top:12px;
    right: 15px;
    width: 20px!important;
    height: 20px!important;
    background-color: transparent!important;
    padding: 0px!important;

  }

  .cc-message {
    margin-right: 0px!important;
    margin-bottom: 15px;
  }

  .cc-compliance {
    flex-direction: column-reverse;
    width: 100%;
    max-width: 265px;
    margin-left: auto;
    margin-right: auto;
  }
  .cc-btn {
    width: 100%!important;
    max-width: 265px!important;
    margin-left: auto!important;
    margin-right: auto!important;
    margin-bottom: 10px!important;
    background-color: $color2!important;
    color: #fff!important;
    display: block!important;
    border-radius: 30px!important;
    font-size: 15px!important;
    font-weight: normal!important;
    padding-top: 10px!important;
    padding-bottom: 10px!important;

    @include bpSp {
      font-size: 13px!important;
    }
    &:hover {
      opacity: 0.75!important;
      text-decoration: none!important;
    }
  }
}

.cc-revoke.cc-bottom.cc-animate {
  display: none!important;
}

.cc-revoke.cc-bottom {
  display: none!important;
}

/*********************************************
animation
*********************************************/
//// animation
.l-inviewContent {
  display: block  ;

  // fadeTop
  &.fadeTop {
    opacity: 0;
    transform: translateY(15px);
    transition: transform .8s ease, opacity .8s ease;
  }

  &.fadeTopImageRight {
    opacity: 0;
  }

  &.normalFade {
    opacity: 0;
    transition: opacity .8s ease;
  }

  &.imageZoom {
    // imgタグにclassを付与。親要素にoverflow: hidden;をつける。
    width: 100%;
    transform: scale(1);
  }

  &.imageZoomOut {
    // imgタグにclassを付与。親要素にoverflow: hidden;をつける。
    width: 100%;
    transform: scale(1.05);
  }

  &.bgSlideSlash,
  &.bgSlideSlashBlue1,
  &.bgSlideSlashWhite,
  &.bgSlideSlashReverse,
  &.bgSlideSlashBlue1Reverse {
    // 親要素にbgSlideSlash、子要素にbgSlideSlashChildをつける。
    position: relative;
    overflow: hidden;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: z(z3);
      background: rgba($color: $color1, $alpha: 0);
    }
  }
  &.bgSlideSlashChild {
    opacity: 0;
  }

  // active
  &.is-active {

    &.normalFade {
      opacity: 1;
    }

    &.fadeTop {
      opacity: 1;
      transform: translateY(0);
    }

    &.fadeTopImageRight {
      &._text1 {
        animation: fadeTopImageRight 3s cubic-bezier(.15,.8,.4,1) forwards;
      }
      &._text2 {
        animation: fadeTopImageRight 4s cubic-bezier(.15,.8,.4,1) forwards;
      }
      &._text3 {
        animation: fadeTopImageRight 5s cubic-bezier(.15,.8,.4,1) forwards;
      }
      &._text4 {
        animation: fadeTopImageRight 6s cubic-bezier(.15,.8,.4,1) forwards;
      }
      &._text5 {
        animation: fadeTopImageRight 7s cubic-bezier(.15,.8,.4,1) forwards;
      }
    }

    &.imageZoom {
      &._2s {
        animation: imageZoom 2s cubic-bezier(0,0.21,0,0.99) forwards;
      }
      &._3s {
        animation: imageZoom 3s cubic-bezier(0,0.21,0,0.99) forwards;
      }
      &._4s {
        animation: imageZoom 4s cubic-bezier(0,0.21,0,0.99) forwards;
      }
    }

    &.imageZoomOut {
      &._2s {
        animation: imageZoomOut 2s cubic-bezier(0,0.21,0,0.99) forwards;
      }
      &._3s {
        animation: imageZoomOut 3s cubic-bezier(0,0.21,0,0.99) forwards;
      }
    }

    &.bgSlideSlash {
      &::before {
        animation: bgSlideSlash 1.5s forwards;
      }
    }
    &.bgSlideSlashBlue1 {
      &::before {
        animation: bgSlideSlashBlue1 1.5s forwards;
      }
    }
    &.bgSlideSlashWhite {
      &::before {
        animation: bgSlideSlashWhite 1.5s forwards;
      }
    }
    &.bgSlideSlashReverse {
      &::before {
        animation: bgSlideSlashReverse 1.5s forwards;
      }
    }
    &.bgSlideSlashBlue1Reverse {
      &::before {
        animation: bgSlideSlashBlue1Reverse 1.5s forwards;
      }
    }
    &.bgSlideSlashChild {
      animation: bgSlideSlashChild 1.5s forwards;
    }

  }

}

/*-----連続で表示-----*/
.inviewListFade01 .inviewListFade01_child {
  opacity: 0;
  transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
}

.listFade01 .inviewListFade01_child {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 1.9s cubic-bezier(0, 0.29, 0.32, 1.01);
  transition: 1.9s cubic-bezier(0, 0.29, 0.32, 1.01);
  -webkit-transition-property: opacity,-webkit-transform;
  transition-property: opacity,-webkit-transform;
  transition-property: transform,opacity;
  transition-property: transform,opacity,-webkit-transform;
  &:nth-child(1) {
    -webkit-transition-delay: 0.5s;
    transition-delay: 0.5s;
  }
  &:nth-child(2) {
    -webkit-transition-delay: 1s;
    transition-delay: 1s;
  }
  &:nth-child(3) {
    -webkit-transition-delay: 1.5s;
    transition-delay: 1.5s;
  }
  &:nth-child(4) {
    -webkit-transition-delay: 2s;
    transition-delay: 2s;
  }
  &:nth-child(5) {
    -webkit-transition-delay: 2.5s;
    transition-delay: 2.5s;
  }
  &:nth-child(6) {
    -webkit-transition-delay: 3s;
    transition-delay: 3s;
  }
  &:nth-child(7) {
    -webkit-transition-delay: 3.5s;
    transition-delay: 3.5s;
  }
  &:nth-child(8) {
    -webkit-transition-delay: 4s;
    transition-delay: 4s;
  }
  &:nth-child(9) {
    -webkit-transition-delay: 4.5s;
    transition-delay: 4.5s;
  }
  &:nth-child(10) {
    -webkit-transition-delay: 5s;
    transition-delay: 5s;
  }
}




//// keyframe
// topimage
@keyframes fadeTopImageRight {
  0% {
    opacity: 0;
    transform: translateX(25px);
  }
  25% {
    opacity: 0;
    transform: translateX(25px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
// Zoom
@keyframes imageZoom {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}
@keyframes imageZoomOut {
  0% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

// bgSlideSlash Child
@keyframes bgSlideSlash {
  // left
  0% {
    transform-origin: left top;
    transform: scale(0, 1);
  }
  40% {
    background: rgba($color: $animationColor, $alpha: 1);
    transform-origin: left top;
    transform: scale(1, 1);
  }
  75% {
    background: rgba($color: $animationColor, $alpha: 1);
    transform-origin: left top;
    transform: scale(1, 1);
  }
  100% {
    background: rgba($color: $animationColor, $alpha: 0);
    transform-origin: left top;
    transform: scale(1, 1);
  }
}
@keyframes bgSlideSlashBlue1 {
  // left
  0% {
    transform-origin: left top;
    transform: scale(0, 1);
  }
  40% {
    background: rgba($color: #121723, $alpha: 1);
    transform-origin: left top;
    transform: scale(1, 1);
  }
  75% {
    background: rgba($color: #121723, $alpha: 1);
    transform-origin: left top;
    transform: scale(1, 1);
  }
  100% {
    background: rgba($color: #121723, $alpha: 0);
    transform-origin: left top;
    transform: scale(1, 1);
  }
}
@keyframes bgSlideSlashWhite {
  // left
  0% {
    transform-origin: left top;
    transform: scale(0, 1);
  }
  40% {
    background: rgba($color: #fff, $alpha: 1);
    transform-origin: left top;
    transform: scale(1, 1);
  }
  75% {
    background: rgba($color: #fff, $alpha: 1);
    transform-origin: left top;
    transform: scale(1, 1);
  }
  100% {
    background: rgba($color: #fff, $alpha: 0);
    transform-origin: left top;
    transform: scale(1, 1);
  }
}
@keyframes bgSlideSlashReverse {
  // right
  0% {
    transform-origin: right top;
    transform: scale(0, 1);
  }
  40% {
    background: rgba($color: $animationColor, $alpha: 1);
    transform-origin: right top;
    transform: scale(1, 1);
  }
  75% {
    background: rgba($color: $animationColor, $alpha: 1);
    transform-origin: right top;
    transform: scale(1, 1);
  }
  100% {
    background: rgba($color: $animationColor, $alpha: 0);
    transform-origin: right top;
    transform: scale(1, 1);
  }
}
@keyframes bgSlideSlashBlue1Reverse {
  // right
  0% {
    transform-origin: right top;
    transform: scale(0, 1);
  }
  40% {
    background: rgba($color: #121723, $alpha: 1);
    transform-origin: right top;
    transform: scale(1, 1);
  }
  75% {
    background: rgba($color: #121723, $alpha: 1);
    transform-origin: right top;
    transform: scale(1, 1);
  }
  100% {
    background: rgba($color: #121723, $alpha: 0);
    transform-origin: right top;
    transform: scale(1, 1);
  }
}
@keyframes bgSlideSlashChild {
  0% {
    opacity: 0;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@include bpSp {
  a:hover {
    opacity: 1;
  }
}